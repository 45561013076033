/**
 * 제작 : 한승현
 * 날짜 : 2022/09/29
 * 내용 : 크롤링 요청을 보내는 폼
 */

import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { isEmail } from 'validator';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import EmailInput from './EmailInput';
import { SendFormContainerStyled } from '../../stylesheets/sendForm/sendForm';
import 'react-toastify/dist/ReactToastify.css';

function SendForm() {
  const { register, handleSubmit, reset } = useForm();

  /**
   * 유저 이메일 유효성 검사 후 api 호출
   * @param { object } data === userEmail
   */
  function onSubmit(data) {
    const { email } = data;
    const domain = '@ohbrown.co.kr'; // 오브라운 계정확인을 위한 변수

    if (!isEmail(email)) {
      toast(`이메일 형식을 확인해주세요.`);
      return;
    }

    if (!email.endsWith(domain)) {
      toast('오브라운 회사 계정만 가능합니다!');
      return;
    }

    toast('서버에 요청 중입니다!');

    const URL = 'https://api-crawler.houseofooh.com/task';
    axios.get(`${URL}?email=${email}`, {});
    reset();
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>오브라운 - 크롤링</title>
      </Helmet>
      <SendFormContainerStyled onSubmit={handleSubmit(onSubmit)}>
        <EmailInput
          type="text"
          id="Email"
          name="Email"
          register={register}
          placeholder="hello@ohbrown.co.kr"
        />
      </SendFormContainerStyled>
    </>
  );
}

export default SendForm;
